import React, { useState, useEffect } from 'react';

import axios from "axios";

import { Button } from 'reactstrap';

const client = axios.create({
  // baseURL: "http://127.0.0.1:8000/posts/"
  baseURL: "https://api.dark-site.ru/users/posts/"
});






const Posts2 = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    client.get('?_limit=10').then((response) => {
      setPosts(response.data);
    });
  }, []);

  return (
    <div className="app">
      <h2>All Posts</h2>
      <hr />
      {posts.map((post) => {
        return (
          <div className="post-card" key={post.id}>

            <h2 className="post-title">{post.title}</h2>
            <p className="post-body">{post.body}</p>

            <div className="button">
              <Button color="primary">Edit</Button>
              <Button color="primary">Delete</Button>
            </div>

            <hr />
          </div>
        );
      })}
    </div>
  );
};

export default Posts2;