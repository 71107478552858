import React from 'react';

import axios from 'axios';

export default class PostList extends React.Component {
  state = {
    posts: []
  }

  componentDidMount() {
//  axios.get(`http://127.0.0.1:8000/posts/`)
    axios.get(`https://api.dark-site.ru/posts/`)
      .then(res => {
        const posts = res.data;
        this.setState({ posts });
      })
  }

  render() {
    return (
      <ul>
        {this.state.posts.map(post => <li>
          <span>название -</span><h2 class="post_title">{post.title}</h2>
          <span>текст -</span><h4 class="post_body">{post.body}</h4>
          <span>автор -</span><p class="post_owner">{post.owner}</p>
          <span>комменты -</span><p class="post_comments">{post.comments}</p>
          <span>категория -</span><p class="post_categories">{post.categories}</p>
          <hr />
          </li>)}
      </ul>
    )
  }
}