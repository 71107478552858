import React from 'react';

const App = () => {

  return (
    <div className="app">
      <h2>Footer</h2>
    </div>
  );
};

export default App;