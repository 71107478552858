import React from 'react';

import { Button, ButtonGroup } from 'reactstrap';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';


const New002 = () => {

  return (
    <div className="app">


    <Container>

      <Row>
        <Col>header</Col>
      </Row>

      <Row>
        <Col className="appCol">
            <Row>
              <Col>header</Col>
              <Col>header</Col>
              <Col>header</Col>
            </Row>
        </Col>
      </Row>

      <Row>
        <Col className="appCol">
            <Row>
              <Col>header</Col>
              <Col>header</Col>
              <Col>header</Col>
            </Row>
        </Col>
      </Row>

      <Row>
        <Col className="appCol">
            <Row>
              <Col>header</Col>
              <Col>header</Col>
              <Col>header</Col>
            </Row>
        </Col>
      </Row>

    </Container>





     
    </div>
  );
};

export default New002;


