import logo from './logo.svg';
import './App.css';

import AxiosUsers from './AxiosUsers.js';
import AxiosPosts from './AxiosPosts.js';
import AxiosPosts2 from './AxiosPosts2.js';
import New001 from './New001.js';
import New002 from './New002.js';

import { Button, ButtonGroup } from 'reactstrap';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {BrowserRouter as Router} from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer';
import Content from './layout/Content';

function App() {
  return (
    <div>

    <Container>

      <Row>
        <Col>header - create-react-app-01</Col>
      </Row>

      <Row>
        <Col>
          <Router>
              <Header />
              <Content />
              <Footer />
          </Router>
        </Col>
      </Row>

      <Row>
        <Col>список юзеров<AxiosUsers /></Col>
        <Col><New002 /></Col>
        <Col><New001 /></Col>
      </Row>

      <Row>
        <Col>список постов<AxiosPosts /></Col>
        <Col>список постов<AxiosPosts2 /></Col>
        <Col>
          <div>
            <Button color="primary">primary</Button>
            <Button color="success">success</Button>
            <Button color="info">info</Button>
            <Button color="warning">warning</Button>
          </div>
          <div>
            <ButtonGroup>
              <Button>
                Left
              </Button>
              <Button>
                Middle
              </Button>
              <Button>
                Right
              </Button>
            </ButtonGroup>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>footer</Col>
      </Row>

    </Container>




        

        

    </div>
  );
}

export default App;
