import React from 'react';
import axios from 'axios';

export default class AxiosUsers extends React.Component {
  state = {
    persons: []
  }

  componentDidMount() {
//    axios.get(`http://127.0.0.1:8000/users/`)
      axios.get(`https://api.dark-site.ru/users/`)


      .then(res => {
        const persons = res.data;
        this.setState({ persons });
      })
  }

  render() {
    return (
      <ul>
        { this.state.persons.map(person => <li>{person.username}</li>)}
      </ul>
    )
  }
}