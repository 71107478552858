import React from 'react';


const New001 = () => {

  return (
    <div className="app">
        <div style={{width: 375, height: 812, position: 'relative', background: 'white'}}>
          <div style={{width: 375, height: 812, left: 0, top: 0, position: 'absolute', background: '#F8F6FF'}} />
          <div style={{width: 375, height: 249, left: 0, top: 0, position: 'absolute', background: 'white', borderTopLeftRadius: 6, borderTopRightRadius: 6}} />
          <div style={{width: 375, height: 98, left: 0, top: 0, position: 'absolute'}}>
            <div style={{width: 375, height: 44, left: 0, top: 0, position: 'absolute'}}>
              <div style={{width: 24.33, height: 11.33, left: 322.33, top: 17.33, position: 'absolute'}}>
                <div style={{width: 22, height: 11.33, left: 0, top: 0, position: 'absolute', opacity: 0.35, borderRadius: 2.67, border: '1px black solid'}} />
                <div style={{width: 1.33, height: 4, left: 23, top: 3.67, position: 'absolute', opacity: 0.40, background: 'black'}}></div>
                <div style={{width: 18, height: 7.33, left: 2, top: 2, position: 'absolute', background: 'black', borderRadius: 1.33}} />
              </div>
              <img style={{width: 15.33, height: 11, left: 302, top: 17.33, position: 'absolute'}} src="https://via.placeholder.com/15x11" />
              <img style={{width: 17, height: 10.67, left: 280, top: 17.67, position: 'absolute'}} src="https://via.placeholder.com/17x11" />
              <div style={{width: 54, left: 21, top: 13, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 15, fontFamily: 'SF Pro Text', fontWeight: '600', lineHeight: 20, wordWrap: 'break-word'}}>9:41</div>
            </div>
            <div style={{left: 108, top: 62, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 24, fontFamily: 'Poppins', fontWeight: '600', wordWrap: 'break-word'}}>Transactions</div>
            <div style={{width: 24, height: 24, left: 324, top: 67, position: 'absolute'}}>
              <div style={{width: 19.50, height: 16.50, left: 2.25, top: 3.75, position: 'absolute'}}>
                <div style={{width: 19.50, height: 4.50, left: 0, top: 0, position: 'absolute', background: 'black'}}></div>
                <div style={{width: 19.50, height: 4.50, left: 0, top: 6, position: 'absolute', background: 'black'}}></div>
                <div style={{width: 19.50, height: 4.50, left: 0, top: 12, position: 'absolute', background: 'black'}}></div>
              </div>
            </div>
            <div style={{width: 24, height: 11.62, left: 52, top: 74, position: 'absolute', transform: 'rotate(180deg)', transformOrigin: '0 0', background: 'black'}}></div>
          </div>
          <div style={{width: 347, height: 732, left: 14, top: 122, position: 'absolute'}}>
            <div style={{width: 347, height: 581, left: 0, top: 151, position: 'absolute'}}>
              <div style={{width: 347, height: 71, left: 0, top: 85, position: 'absolute'}}>
                <div style={{width: 347, height: 71, left: 0, top: 0, position: 'absolute', background: '#F3F0FB', borderRadius: 12}} />
                <div style={{width: 51, height: 51, left: 14, top: 10, position: 'absolute', background: '#E2E2E2', borderRadius: 8}} />
                <div style={{width: 51, height: 51, left: 14, top: 10, position: 'absolute'}}>
                  <div style={{width: 51, height: 51, left: 0, top: 0, position: 'absolute', background: '#3D454B', borderRadius: 8}} />
                  <div style={{width: 22, height: 24, left: 15, top: 14, position: 'absolute', background: '#959595'}}></div>
                </div>
                <div style={{width: 245, height: 46, left: 87, top: 13, position: 'absolute'}}>
                  <div style={{left: 0, top: 0, position: 'absolute', color: 'black', fontSize: 16, fontFamily: 'Poppins', fontWeight: '500', wordWrap: 'break-word'}}>Legendary Bank</div>
                  <div style={{left: 183, top: 0, position: 'absolute', textAlign: 'right', color: '#363062', fontSize: 16, fontFamily: 'Poppins', fontWeight: '700', wordWrap: 'break-word'}}>-$1,000</div>
                  <div style={{left: 0, top: 25, position: 'absolute', color: '#6B6B6B', fontSize: 14, fontFamily: 'Poppins', fontWeight: '400', wordWrap: 'break-word'}}>Top Up</div>
                  <div style={{left: 196, top: 25, position: 'absolute', textAlign: 'right', color: '#6B6B6B', fontSize: 14, fontFamily: 'Poppins', fontWeight: '400', wordWrap: 'break-word'}}>1w ago</div>
                </div>
              </div>
              <div style={{width: 347, height: 71, left: 0, top: 510, position: 'absolute'}}>
                <div style={{width: 347, height: 71, left: 0, top: 0, position: 'absolute', background: '#F3F0FB', borderRadius: 12}} />
                <div style={{width: 51, height: 51, left: 14, top: 10, position: 'absolute', background: '#E2E2E2', borderRadius: 8}} />
                <div style={{width: 51, height: 51, left: 14, top: 10, position: 'absolute'}}>
                  <div style={{width: 51, height: 51, left: 0, top: 0, position: 'absolute', background: '#3D454B', borderRadius: 8}} />
                  <div style={{width: 22, height: 24, left: 15, top: 14, position: 'absolute', background: '#959595'}}></div>
                </div>
                <div style={{width: 245, height: 46, left: 87, top: 13, position: 'absolute'}}>
                  <div style={{left: 0, top: 0, position: 'absolute', color: 'black', fontSize: 16, fontFamily: 'Poppins', fontWeight: '500', wordWrap: 'break-word'}}>Legendary Bank</div>
                  <div style={{left: 183, top: 0, position: 'absolute', textAlign: 'right', color: '#363062', fontSize: 16, fontFamily: 'Poppins', fontWeight: '700', wordWrap: 'break-word'}}>-$1,000</div>
                  <div style={{left: 0, top: 25, position: 'absolute', color: '#6B6B6B', fontSize: 14, fontFamily: 'Poppins', fontWeight: '400', wordWrap: 'break-word'}}>Top Up</div>
                  <div style={{left: 196, top: 25, position: 'absolute', textAlign: 'right', color: '#6B6B6B', fontSize: 14, fontFamily: 'Poppins', fontWeight: '400', wordWrap: 'break-word'}}>1w ago</div>
                </div>
              </div>
              <div style={{width: 347, height: 71, left: 0, top: 0, position: 'absolute'}}>
                <div style={{width: 347, height: 71, left: 0, top: 0, position: 'absolute', background: '#F3F0FB', borderRadius: 12}} />
                <div style={{width: 51, height: 51, left: 14, top: 10, position: 'absolute', background: '#E2E2E2', borderRadius: 8}} />
                <div style={{width: 51, height: 51, left: 14, top: 10, position: 'absolute'}}>
                  <div style={{width: 51, height: 51, left: 0, top: 0, position: 'absolute', background: '#90CE7B', borderRadius: 8}} />
                  <div style={{width: 26, height: 26, left: 12, top: 12, position: 'absolute', background: 'white'}}></div>
                </div>
                <div style={{width: 245, height: 46, left: 87, top: 13, position: 'absolute'}}>
                  <div style={{left: 0, top: 0, position: 'absolute', color: 'black', fontSize: 16, fontFamily: 'Poppins', fontWeight: '500', wordWrap: 'break-word'}}>Green Bank</div>
                  <div style={{left: 203, top: 0, position: 'absolute', textAlign: 'right', color: '#363062', fontSize: 16, fontFamily: 'Poppins', fontWeight: '700', wordWrap: 'break-word'}}>$800</div>
                  <div style={{left: 0, top: 25, position: 'absolute', color: '#6B6B6B', fontSize: 14, fontFamily: 'Poppins', fontWeight: '400', wordWrap: 'break-word'}}>Withdraw</div>
                  <div style={{left: 194, top: 25, position: 'absolute', textAlign: 'right', color: '#6B6B6B', fontSize: 14, fontFamily: 'Poppins', fontWeight: '400', wordWrap: 'break-word'}}>4d ago</div>
                </div>
              </div>
              <div style={{width: 347, height: 71, left: 0, top: 425, position: 'absolute'}}>
                <div style={{width: 347, height: 71, left: 0, top: 0, position: 'absolute', background: '#F3F0FB', borderRadius: 12}} />
                <div style={{width: 51, height: 51, left: 14, top: 10, position: 'absolute', background: '#E2E2E2', borderRadius: 8}} />
                <div style={{width: 51, height: 51, left: 14, top: 10, position: 'absolute'}}>
                  <div style={{width: 51, height: 51, left: 0, top: 0, position: 'absolute', background: '#CE83E0', borderRadius: 8}} />
                  <div style={{width: 16, height: 21, left: 18, top: 15, position: 'absolute', background: 'white'}}></div>
                </div>
                <div style={{width: 245, height: 46, left: 87, top: 13, position: 'absolute'}}>
                  <div style={{left: 0, top: 0, position: 'absolute', color: 'black', fontSize: 16, fontFamily: 'Poppins', fontWeight: '500', wordWrap: 'break-word'}}>Purple Bank</div>
                  <div style={{left: 203, top: 0, position: 'absolute', textAlign: 'right', color: '#363062', fontSize: 16, fontFamily: 'Poppins', fontWeight: '700', wordWrap: 'break-word'}}>$800</div>
                  <div style={{left: 0, top: 25, position: 'absolute', color: '#6B6B6B', fontSize: 14, fontFamily: 'Poppins', fontWeight: '400', wordWrap: 'break-word'}}>Withdraw</div>
                  <div style={{left: 194, top: 25, position: 'absolute', textAlign: 'right', color: '#6B6B6B', fontSize: 14, fontFamily: 'Poppins', fontWeight: '400', wordWrap: 'break-word'}}>4d ago</div>
                </div>
              </div>
              <div style={{width: 347, height: 71, left: 0, top: 255, position: 'absolute'}}>
                <div style={{width: 347, height: 71, left: 0, top: 0, position: 'absolute', background: '#F3F0FB', borderRadius: 12}} />
                <div style={{width: 51, height: 51, left: 14, top: 10, position: 'absolute', background: '#E2E2E2', borderRadius: 8}} />
                <div style={{width: 51, height: 51, left: 14, top: 10, position: 'absolute'}}>
                  <div style={{width: 51, height: 51, left: 0, top: 0, position: 'absolute', background: 'white', borderRadius: 8, border: '1px #EFEFEF solid'}} />
                  <div style={{width: 21, height: 26, left: 15, top: 13, position: 'absolute', background: '#2086E5'}}></div>
                </div>
                <div style={{width: 245, height: 46, left: 87, top: 13, position: 'absolute'}}>
                  <div style={{left: 0, top: 0, position: 'absolute', color: 'black', fontSize: 16, fontFamily: 'Poppins', fontWeight: '500', wordWrap: 'break-word'}}>Kaya Bank</div>
                  <div style={{left: 203, top: 0, position: 'absolute', textAlign: 'right', color: '#61C277', fontSize: 16, fontFamily: 'Poppins', fontWeight: '700', wordWrap: 'break-word'}}>$850</div>
                  <div style={{left: 0, top: 25, position: 'absolute', color: '#6B6B6B', fontSize: 14, fontFamily: 'Poppins', fontWeight: '400', wordWrap: 'break-word'}}>Top Up</div>
                  <div style={{left: 194, top: 25, position: 'absolute', textAlign: 'right', color: '#6B6B6B', fontSize: 14, fontFamily: 'Poppins', fontWeight: '400', wordWrap: 'break-word'}}>4d ago</div>
                </div>
              </div>
              <div style={{width: 347, height: 71, left: 0, top: 170, position: 'absolute'}}>
                <div style={{width: 347, height: 71, left: 0, top: 0, position: 'absolute', background: '#F3F0FB', borderRadius: 12}} />
                <div style={{width: 51, height: 51, left: 14, top: 10, position: 'absolute', background: '#E2E2E2', borderRadius: 8}} />
                <div style={{width: 51, height: 51, left: 14, top: 10, position: 'absolute', background: '#FF922E', borderRadius: 8}} />
                <div style={{width: 15, height: 21, left: 32, top: 25, position: 'absolute', background: 'white'}}></div>
                <div style={{width: 245, height: 46, left: 87, top: 13, position: 'absolute'}}>
                  <div style={{left: 0, top: 0, position: 'absolute', color: 'black', fontSize: 16, fontFamily: 'Poppins', fontWeight: '500', wordWrap: 'break-word'}}>Bitkoin</div>
                  <div style={{left: 193, top: 0, position: 'absolute', textAlign: 'right', color: '#363062', fontSize: 16, fontFamily: 'Poppins', fontWeight: '700', wordWrap: 'break-word'}}>-$540</div>
                  <div style={{left: 0, top: 25, position: 'absolute', color: '#6B6B6B', fontSize: 14, fontFamily: 'Poppins', fontWeight: '400', wordWrap: 'break-word'}}>SELL</div>
                  <div style={{left: 196, top: 25, position: 'absolute', textAlign: 'right', color: '#6B6B6B', fontSize: 14, fontFamily: 'Poppins', fontWeight: '400', wordWrap: 'break-word'}}>1w ago</div>
                </div>
              </div>
              <div style={{width: 347, height: 71, left: 0, top: 340, position: 'absolute'}}>
                <div style={{width: 347, height: 71, left: 0, top: 0, position: 'absolute', background: '#F3F0FB', borderRadius: 12}} />
                <div style={{width: 51, height: 51, left: 14, top: 10, position: 'absolute', background: '#E2E2E2', borderRadius: 8}} />
                <img style={{width: 51, height: 51, left: 14, top: 10, position: 'absolute', borderRadius: 8}} src="https://via.placeholder.com/51x51" />
                <div style={{width: 245, height: 46, left: 87, top: 13, position: 'absolute'}}>
                  <div style={{left: 0, top: 0, position: 'absolute', color: 'black', fontSize: 16, fontFamily: 'Poppins', fontWeight: '500', wordWrap: 'break-word'}}>Olivia Johanson</div>
                  <div style={{left: 190, top: 0, position: 'absolute', textAlign: 'right', color: '#61C277', fontSize: 16, fontFamily: 'Poppins', fontWeight: '700', wordWrap: 'break-word'}}>$432.9</div>
                  <div style={{left: 0, top: 25, position: 'absolute', color: '#6B6B6B', fontSize: 14, fontFamily: 'Poppins', fontWeight: '400', wordWrap: 'break-word'}}>Transfer</div>
                  <div style={{left: 190, top: 25, position: 'absolute', textAlign: 'right', color: '#6B6B6B', fontSize: 14, fontFamily: 'Poppins', fontWeight: '400', wordWrap: 'break-word'}}>3.30 AM</div>
                </div>
              </div>
            </div>
            <div style={{width: 251, height: 34, left: 15, top: 81, position: 'absolute'}}>
              <div style={{left: 189, top: 5, position: 'absolute', color: '#BDBDBD', fontSize: 16, fontFamily: 'Poppins', fontWeight: '600', wordWrap: 'break-word'}}>Income</div>
              <div style={{width: 62, height: 34, left: 0, top: 0, position: 'absolute'}}>
                <div style={{width: 62, height: 34, left: 0, top: 0, position: 'absolute', background: 'rgba(54, 48, 98, 0.05)', borderRadius: 20}} />
                <div style={{left: 21, top: 5, position: 'absolute', color: '#363062', fontSize: 16, fontFamily: 'Poppins', fontWeight: '600', wordWrap: 'break-word'}}>All</div>
              </div>
              <div style={{left: 96, top: 5, position: 'absolute', color: '#BDBDBD', fontSize: 16, fontFamily: 'Poppins', fontWeight: '600', wordWrap: 'break-word'}}>Expense</div>
            </div>
            <div style={{width: 319, height: 57, left: 15, top: 0, position: 'absolute'}}>
              <div style={{width: 319, height: 57, left: 0, top: 0, position: 'absolute', borderRadius: 8, border: '1px #E2E2E2 solid'}} />
              <div style={{left: 19, top: 19, position: 'absolute', color: '#6B6B6B', fontSize: 14, fontFamily: 'Poppins', fontWeight: '400', wordWrap: 'break-word'}}>Search here</div>
              <div style={{width: 23.99, height: 24, left: 271, top: 16, position: 'absolute', background: '#A3A3A3'}}></div>
            </div>
          </div>
          <div style={{width: 375, height: 35, left: 0, top: 777, position: 'absolute'}}>
            <div style={{width: 375, height: 35, left: 0, top: 0, position: 'absolute', background: 'rgba(255, 255, 255, 0)'}}></div>
            <div style={{width: 134, height: 5, left: 121, top: 21, position: 'absolute', background: '#DDDDDD'}} />
          </div>
        </div>
    </div>
  );
};

export default New001;


